<template>
  <div>
    <!-- /.modal -->
    <div
      class="modal fade"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="exampleModalLabel">
                <i class="fa fa-plus"></i> Add Vending
              </h5>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  >Vending Name<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="recipient-name"
                  required
                  v-model="form.name"
                  :disabled="loading"
                />
              </div>
              <div class="form-group">
                <label for="recipient-vending-type" class="col-form-label"
                  >Vending Type</label
                >
                <select2
                  v-model="form.vending_type.id"
                  :config="vendingTypeConfig"
                  class="form-control"
                  placeholder="Choose Vending Type"
                >
                  <option
                    :value="form.vending_type.id"
                    selected
                    v-if="form.vending_type.id && this.$route.params.id"
                  >
                    {{ form.vending_type.type_name }}
                  </option>
                </select2>
              </div>
              <div class="form-group">
                <label for="recipient-merchant" class="col-form-label"
                  >Merchant Name<span class="text-red">*</span></label
                >
                <select2
                  v-model="form.merchant.id"
                  required
                  :config="merchantConfig"
                  class="form-control"
                  placeholder="Choose Merchant Name"
                >
                  <option
                    :value="form.merchant.id"
                    selected
                    v-if="form.merchant.id && this.$route.params.id"
                  >
                    {{ form.merchant.name }}
                  </option>
                </select2>
              </div>
              <div class="form-group">
                <label for="recipient-vending_id" class="col-form-label"
                  >ID Vending<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  required
                  id="recipient-vending_id"
                  v-model="form.vending_id"
                  :disabled="loading"
                />
              </div>

              <div class="form-group">
                <label for="recipient-row_slot" class="col-form-label"
                  >Baris Slot<span class="text-red">*</span></label
                >
                <input
                  type="number"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  class="form-control"
                  required
                  id="recipient-row_slot"
                  v-model="form.row_slot"
                  :disabled="loading"
                />
              </div>
              <div class="form-group">
                <label for="recipient-col_slot" class="col-form-label"
                  >Kolom Slot<span class="text-red">*</span></label
                >
                <input
                  type="number"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  class="form-control"
                  required
                  id="recipient-col_slot"
                  v-model="form.col_slot"
                  :disabled="loading"
                />
              </div>
              <div class="form-group">
                <label for="recipient-address" class="col-form-label"
                  >Address<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  required
                  id="recipient-address"
                  v-model="form.address"
                  :disabled="loading"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ loading ? "Saving ..." : "Save" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-3">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark" style="font-size: 2em">Vending</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6" :hidden="this.userRole == 'ROLE_MERCHANT'">
            <ol class="breadcrumb float-sm-right">
              <!-- <li>
                <button
                  type="button"
                  class="btn btn-block btn-outline-primary btn-lg"
                >
                  Export
                </button>
              </li> -->
              <li class="">
                <button
                  type="button"
                  class="btn btn-block btn-primary btn-lg"
                  @click="showModal"
                >
                  <span class="fas fa-plus mr-2"></span> Add Vending
                </button>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-9">
            <div class="dropdown vm-dropdown-vending">
              <button
                class="btn border border-secondary bg-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-filter mr-2"></span>Filter
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <span class="dropdown-item disabled text-secondary"
                  >Status :</span
                >
                <span class="dropdown-item text-primary">
                  <span class="fas fa-circle mr-2" style="font-size: 8px"></span
                  >Ready
                </span>
                <span class="dropdown-item text-warning">
                  <span class="fas fa-circle mr-2" style="font-size: 8px"></span
                  >Perbaikan
                </span>
                <span class="dropdown-item text-success">
                  <span class="fas fa-circle mr-2" style="font-size: 8px"></span
                  >Active
                </span>
                <span class="dropdown-item text-danger">
                  <span class="fas fa-circle mr-2" style="font-size: 8px"></span
                  >Nonactive
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 d-flex justify-content-end">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control border-right-0 border border-secondary"
                placeholder="Cari merch, ID, no Vending..."
                aria-label="Cari merch, ID, no Vending..."
                aria-describedby="basic-addon2"
                v-model="searchTerm"
                @change="searchVending"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text text-primary bg-white border-left-0 border-secondary"
                  id="basic-addon2"
                >
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-3 col-md-3 col-sm-6"
        v-for="items in merchantList"
        :key="items?.id"
      >
        <div class="card vm-card-vending">
          <div class="card-header text-secondary">
            <div class="row">
              <div class="col d-flex align-items-center">
                {{ items?.vending_id }}
              </div>
              <div class="col d-flex justify-content-end">
                <div class="dropdown">
                  <button
                    class="btn btn-sm"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="fas fa-ellipsis-h text-secondary"></span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'AssignVendingDinamis',
                        query: {
                          vendingId: items?.id,
                          isDetail: isDetail,
                        },
                        params: {
                          id: items?.id,
                          isDetail: isDetail,
                          row: items?.row_slot ? items?.row_slot : 5,
                          col: items?.col_slot ? items?.col_slot : 8,
                        },
                      }"
                    >
                      Detail Vending
                    </router-link>
                    <!-- <span class="dropdown-item" style="cursor: pointer;"> -->
                    <router-link
                      class="dropdown-item"
                      :to="{
                        name: 'AssignVendingDinamis',
                        query: { vendingId: items?.id },
                        params: {
                          id: items?.id,
                          row: items?.row_slot ? items?.row_slot : 5,
                          col: items?.col_slot ? items?.col_slot : 8,
                        },
                      }"
                    >
                      Edit Vending
                    </router-link>
                    <!-- </span> -->
                    <!-- <span class="dropdown-item" style="cursor: pointer;">
                      Delete Vending
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="vending-title">{{ items.name }}</div>
            <div class="text-primary company-title">
              <span class="fas fa-building mr-2"></span
              >{{ items?.address ?? "" }}
            </div>
          </div>

          <div class="card-footer">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <span class="badge">{{ items?.status }}</span>
              </div>
              <div class="col-sm-12 col-md-6">
                <!-- For Message -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 d-flex justify-content-end vm-card-data-table">
        <ul class="pagination">
          <li
            class="paginate_button page-item first"
            id="DataTables_Table_2_first"
            v-if="pagination.last"
          >
            <a @click="changePage(1)" class="page-link" style="cursor: pointer"
              ><i class="fas fa-angle-double-left"></i
            ></a>
          </li>
          <li
            class="paginate_button page-item previous"
            id="DataTables_Table_2_previous"
            v-if="pagination.last"
          >
            <a
              @click="changePage(pagination.page - 1)"
              class="page-link"
              style="cursor: pointer"
              ><i class="fas fa-angle-left"></i
            ></a>
          </li>
          <li
            v-for="index in pagination.total_pages"
            :key="index"
            class="paginate_button page-item"
            :class="pagination.page === index ? 'active' : ''"
          >
            <a
              @click="changePage(index)"
              class="page-link"
              style="cursor: pointer"
              >{{ index }}</a
            >
          </li>
          <li
            class="paginate_button page-item next"
            id="DataTables_Table_2_next"
            v-if="pagination.first"
          >
            <a
              @click="changePage(pagination.page + 1)"
              class="page-link"
              style="cursor: pointer"
              ><i class="fas fa-angle-right"></i
            ></a>
          </li>
          <li
            class="paginate_button page-item last"
            id="DataTables_Table_2_last"
            v-if="pagination.first"
          >
            <a
              @click="changePage(pagination.total_pages)"
              class="page-link"
              style="cursor: pointer"
              ><i class="fas fa-angle-double-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const { console } = window;
import axios from "axios";

import "@assets/scss/card.scss";
import "@assets/scss/data-tables.scss";

import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      merchantList: null,
      pagination: {
        first: false,
        last: false,
        page: 1,
        size: 10,
        total_elements: 0,
        total_pages: 0,
      },
      active: false,
      errorDialog: null,
      errorText: "",
      uploadFieldName: "file",
      error: undefined,
      loading: false,
      text_validation: null,
      searchTerm: "",
      filter: [],
      maxSize: 1024,
      userRole: "",
      form: {
        id: null,
        vending_id: "",
        name: "",
        address: "",
        row_slot: null,
        col_slot: null,
        merchant: {
          id: null,
        },
        vending_type: {
          id: null,
        },
      },
      merchantConfig: {},
      vendingTypeConfig: {},
      disabled: false,
      isDetail: true,
    };
  },
  computed: {
    console: () => console,
    // merchantListExpand() {
    //   if (this.merchantList) {
    //     const results = [];
    //     this.merchantList.map((item, key) => {
    //       item.vending.map((ven) => {
    //         results.push({
    //           ...ven,
    //           merchant_detail: item,
    //         });
    //       });
    //     });
    //     return results.filter(
    //       (item) =>
    //         item.name?.toLowerCase().includes(this.searchTerm?.toLowerCase()) ||
    //         item.vending_id
    //           .toLowerCase()
    //           .includes(this.searchTerm?.toLowerCase()) ||
    //         item.merchant_detail.name
    //           .toLowerCase()
    //           .includes(this.searchTerm?.toLowerCase())
    //     );
    //   } else {
    //     return null;
    //   }
    // },
  },
  mounted() {
    this.userRole = Api.getUserRole();
    this.initData();
  },
  created() {
    this.getData();
  },
  props: {
    value: Object,
  },
  methods: {
    changePage(page) {
      this.pagination.page = page;
      this.getData();
    },
    add() {
      this.$router.push({ name: "AssignVending" });
    },
    initData() {
      (this.merchantConfig = {
        ajax: {
          url: env.api.base + "/merchant?sort=id",
          data: (params) => {
            var filters = [["name", "like", params.term]];
            var query = {
              sort: "name",
              filters: params.term ? JSON.stringify(filters) : "",
              page: params.page ? params.page : 1,
            };

            return query;
          },
          processResults: (data, params) => {
            data.content.forEach((item) => {
              item.text = item.name;
            });
            return {
              results: data.content,
              pagination: {
                more: data.last == false,
              },
            };
          },
        },
      }),
        (this.vendingTypeConfig = {
          ajax: {
            url: env.api.base + "/vendingType?sort=id",
            data: (params) => {
              var filters = [["type_name", "like", params.term]];
              var query = {
                sort: "type_name",
                filters: params.term ? JSON.stringify(filters) : "",
                page: params.page ? params.page : 1,
              };

              return query;
            },
            processResults: (data, params) => {
              data.content.forEach((item) => {
                item.text = item.type_name;
              });
              return {
                results: data.content,
                pagination: {
                  more: data.last == false,
                },
              };
            },
          },
        });
    },

    searchVending(e) {
      // name, vending_id, merchant_detail.name
      this.searchTerm = e.target.value;
      this.filter = [
        ["name", "like", this.searchTerm],
        ["or"],
        ["vending.name", "like", this.searchTerm],
      ];
      this.getData();
    },

    async getData() {
      // const filterSearch =
      // this.filter.length > 0 ? JSON.stringify(this.filter) : "";
      await this.Api.get(
        `/vending?page=${this.pagination.page}&size=${this.pagination.size}`
      )
        .then((res) => {
          const data = res.data;
          this.pagination = {
            first: data.first,
            last: data.last,
            page: data.number + 1,
            size: data.size,
            total_elements: data.total_elements,
            total_pages: data.total_pages,
          };
          this.merchantList = data.content;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    showModal() {
      this.form.id = null;
      this.form.name = null;
      this.form.vending_id = null;
      this.form.address = null;
      this.form.row_slot = null;
      this.form.col_slot = null;

      $("#exampleModal").modal("show");
    },
    save() {
      this.loading = true;
      this.Api.post("/vending", this.form)
        .then((res) => {
          this.loading = false;
          this.initData();
          this.getData();
          $("#exampleModal").modal("hide");
        })
        .catch((e) => {
          const message = e.response.data.message;
          this.loading = false;
          this.error = e.response.data.message;
          this.text_validation = "Submit Failed";
          Swal.fire({
            title: "Submit Failed!",
            text:
              message.toLowerCase() ===
              "vending ID sudah digunakan".toLowerCase()
                ? "Vending ID Already Exists"
                : message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/merchant",
      };
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
<style lang="scss">
@import "@assets/scss/custom.scss";

.vm-dropdown-vending {
  margin-bottom: 0px;
}

@include media-breakpoint-down(sm) {
  .vm-dropdown-vending {
    margin-bottom: 10px;
  }
}
</style>
